<template>
  <div class="setting-filiale-sci">
    <div class="box-setting-header ">
      <div class="row">
        <div class="title ml-4 mt-2">Gestion Configurations</div>
        <div class="title loaderPosition">
          <div v-if="getIsLoding" class="loading mt-1 ml-2">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
        </div>
      </div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addConfigurationModal
          ><font-awesome-icon icon="plus" /> Ajouter une configuration</b-button
        >
        <b-modal
          ref="addConfigurationModal"
          id="addConfigurationModal"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          no-close-on-backdrop
          modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une configuration</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addConfigurationModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitConfiguration"
                class="form-modal-custom-style"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Société à configurer"
                        label-for="filiale-name"
                        class="input-modal-champ mb-0 "
                      >
                        <b-form-select
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.filiale_id"
                          value-field="id"
                          text-field="name"
                          :options="getFilialsSupport"
                          required
                          @change="checkErrorsForAdd"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group
                        label="Acheteur principale"
                        label-for="filiale-name"
                        class="input-modal-champ mb-0 "
                      >
                        <b-form-select
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.acheteur_id"
                          value-field="id"
                          text-field="name"
                          :options="getProFilials"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group
                        label="TVA"
                        label-for="TVA"
                        class="input-modal-champ mb-0 col-3"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.tva"
                          required
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Début de configuration"
                        label-for="Début de configuration"
                        class="input-modal-champ mb-0 pl-0 "
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          required
                          v-model="configurationToAdd.date_debut"
                          :clear-button="true"
                          @input="checkErrorsForAdd"
                          :disabled-date="disabledStartDate"
                        ></date-picker>
                      </b-form-group>
                      <b-form-group
                        label="Fin du configuration"
                        label-for="filiale-name"
                        class="input-modal-champ mb-0"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="configurationToAdd.date_fin"
                          @change="checkErrorsForAdd"
                          :clear-button="true"
                          :disabled-date="disabledEndDate"
                        ></date-picker>
                      </b-form-group>
                    </div>
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Type de Coefficient"
                        label-for="filiale-name"
                        class="input-modal-champ mb-0"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.coef_type"
                          :options="coef_type_reduction_options"
                          required
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Réduction"
                        label-for="Coefficient"
                        class="input-modal-champ mb-0 "
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="configurationToAdd.reduction"
                          required
                          type="number"
                          step="any"
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Type de réduction"
                        label-for="filiale-name"
                        class="input-modal-champ mb-0"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.reduction_type"
                          required
                          :options="coef_type_reduction_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Coefficient"
                        label-for="Coefficient"
                        class="input-modal-champ mb-0 col-4"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.coef"
                          required
                          type="number"
                          step="any"
                          class="b-form-select-raduis"
                          :disabled="computedDisabledCoeffition"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Label"
                        label-for="label"
                        class="input-modal-champ mb-0"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="configurationToAdd.label"
                          required
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label=""
                        label-for=""
                        class="input-modal-champ mb-0"
                      >
                        <button
                          type="button"
                          @click="addNewcoeffition('add')"
                          class="ml-2  btn-add-tva-unite"
                        >
                          <font-awesome-icon
                            icon="plus"
                            class="icon-plus-tva-unite"
                          />
                        </button>
                      </b-form-group>
                    </div>
                    <div
                      v-for="(coeff, index) in configurationToAdd.coefs"
                      :key="index"
                    >
                      <div class="ModelCol mr-3">
                        <b-form-group
                          label="Coefficient"
                          label-for="Coefficient"
                          class="input-modal-champ mb-0 col-4"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="filiale-name"
                            v-model="coeff.coef"
                            required
                            type="number"
                            step="any"
                            class="b-form-select-raduis"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Label"
                          label-for="label"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="filiale-name"
                            v-model="coeff.label"
                            required
                            class="b-form-select-raduis"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ mb-0">
                          <b-button
                            class="button-danger-style ml-1 float-left mt-4"
                            size="sm"
                            variant="danger"
                            @click="deleteCoefficient(index, 'add')"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>
                        </b-form-group>
                      </div>
                    </div>
                    <hr />
                    <div v-if="configurationToAdd.coef_type === 'pourcentage'">
                      <div class="d-flex justify-content-start text-dark mt-1">
                        <p><b>Formules:</b></p>
                      </div>
                      <div class="ModelCol mr-3">
                        <b-button
                          variant="success"
                          class="config-btn d-flex justify-content-start ml-2"
                          @click="addNewFormule('add')"
                          ><font-awesome-icon icon="plus" /> Ajouter une
                          formule</b-button
                        >
                        <div>
                          <b-form-group
                            v-if="configurationToAdd.formules.length > 1"
                            label=""
                            label-for=""
                            class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                          >
                            <b-form-select
                              autocomplete="off"
                              id="and_or"
                              v-model="configurationToAdd.formule_types"
                              required
                              :options="formule_types_options"
                              class="b-form-select-raduis"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                        <div>
                          <b-form-group
                            label="Colonne"
                            label-for="colonne"
                            class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                          >
                            <b-form-select
                              autocomplete="off"
                              :id="'condition_type'"
                              @input="CopyFields($event)"
                              :options="getNumeriqCulomn"
                              class="b-form-select-raduis"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                        <div>
                          <div class="CopieMsg" v-if="isTextCopied">
                            Text copier
                          </div>
                        </div>
                      </div>
                      <div
                        v-for="(formu, index) in configurationToAdd.formules"
                        :key="index"
                      >
                        <div class="ModelCol mr-3">
                          <b-form-group
                            label="Formule"
                            label-for="formule"
                            class="input-modal-champ mb-0 col-lg-11"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="filiale-name"
                              v-model="formu.formule"
                              rows="3"
                              required
                              class="b-form-select-raduis"
                            ></b-form-input>
                          </b-form-group>

                          <b-form-group class="input-modal-champ mb-0 col-lg-1">
                            <b-button
                              class="button-danger-style  mt-4"
                              size="sm"
                              variant="danger"
                              @click="deleteFormule(index, 'add')"
                            >
                              <font-awesome-icon icon="trash" />
                            </b-button>
                          </b-form-group>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <p class="d-flex justify-content-start text-dark">
                      <b> Condition de configuration</b>
                    </p>
                    <div class="ModelCol mr-3">
                      <b-button
                        variant="success"
                        class="config-btn d-flex justify-content-start ml-2"
                        @click="addNewCondition('add')"
                        ><font-awesome-icon icon="plus" /> Ajouter une
                        condition</b-button
                      >
                      <div>
                        <b-form-group
                          v-if="configurationToAdd.conditions.length > 1"
                          label=""
                          label-for="filiale-name"
                          class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                        >
                          <b-form-select
                            autocomplete="off"
                            id="and_or"
                            v-model="configurationToAdd.condition_types"
                            required
                            :options="and_or_options"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                    <div
                      v-for="(condition,
                      index) in configurationToAdd.conditions"
                      :key="index"
                    >
                      <div class="ModelCol mr-3">
                        <p class="m-0" hidden>
                          {{ (condition.index = index) }}
                        </p>
                        <b-form-group
                          label="Colonne"
                          label-for="colonne"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-select
                            autocomplete="off"
                            id="filiale-name"
                            v-model="condition.column"
                            required
                            :options="getColumns"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          label="Operation"
                          label-for="Operation"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-select
                            autocomplete="off"
                            id="filiale-name"
                            v-model="condition.operation"
                            required
                            :options="operation_options"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                        <b-form-group
                          label="Valeur"
                          label-for="Valeur"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-input
                            autocomplete="off"
                            v-model="condition.value"
                            class="b-form-select-raduis"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group class="input-modal-champ mb-0 ">
                          <b-button
                            class="button-danger-style  mt-4"
                            size="sm"
                            variant="danger"
                            @click="deleteCondition(index, 'add')"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>
                        </b-form-group>
                      </div>
                      <hr />
                    </div>

                    <div
                      v-if="
                        configurationToAdd.filiale_id != null &&
                          configurationToAdd.date_debut != null
                      "
                    >
                      <div class="d-flex justify-content-start text-dark mt-1">
                        <p><b>Specifics:</b></p>
                      </div>
                      <b-form-checkbox
                        v-model="checkAll"
                        switch
                        @change="changeAllCheckColumns"
                        >Tous</b-form-checkbox
                      >
                      <br />
                      <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <div class="custom-class-fields-av">
                          <b-form-checkbox
                            v-for="(option, index) in computedFormatFilialList"
                            :key="index"
                            v-model="configurationToAdd.specifics"
                            :aria-describedby="ariaDescribedby"
                            :value="{ filiale_id: option.filiale_id }"
                            @change="checkErrorsForAdd"
                          >
                            {{ option.name }}
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="getConfigurationError" class="error">
                      <ul
                        v-if="Array.isArray(getConfigurationError)"
                        class="mb-0"
                        style="list-style-type: none;"
                      >
                        <li
                          v-for="(error, index) in getConfigurationError"
                          :key="index"
                        >
                          {{ error }}
                        </li>
                      </ul>
                      <div v-else>{{ getConfigurationError }}</div>
                    </div>
                    <div v-if="getErrorForSpecific" class="error">
                      <div class="d-flex flex-row justify-content-center">
                        <p>{{ getErrorForSpecific.error }}</p>
                        <div class="ml-2 bckground-icon-info ">
                          <font-awesome-icon
                            class="info-icon"
                            :id="'tooltip-societe'"
                            icon="info"
                          />
                        </div>
                      </div>
                      <b-tooltip :target="'tooltip-societe'" triggers="hover">
                        <ul class="mb-0" style="list-style-type: none;">
                          <li
                            v-for="(error,
                            index) in getErrorForSpecific.acheteurs"
                            :key="index"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </b-tooltip>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style"
                    :disabled="!validateToAddconf"
                  >
                    <span>
                      Valider
                      <div v-if="getIsLoding" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter mb-2">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Date début</span>
        </div>
        <date-picker
          input-class="date-picker-for-filter"
          value-type="format"
          type="date"
          v-model="dateStartforSearch"
          :clear-button="true"
          @input="searchFilter"
          :disabled-date="disabledStartDateFilter"
        ></date-picker>
      </div>
      <div class="box-label-champ ml-2">
        <div class="label-box-style">
          <span class="title-tabel">Date fin</span>
        </div>
        <date-picker
          input-class="date-picker-for-filter"
          value-type="format"
          type="date"
          v-model="dateEndForSearch"
          :clear-button="true"
          @input="searchFilter"
          :disabled-date="disabledEndDateFiltre"
        ></date-picker>
      </div>
      <div class="box-end-position">
        <div class="Search-style-component">
          <div class="search-box">
            <font-awesome-icon icon="search" class="icon" />

            <input
              @input="searchFilter"
              v-model="nom_societe"
              class="search-input"
              placeholder="Chercher"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table-simple class="table-fiche custom-table-style mb-0" responsive>
          <b-tbody>
            <b-tr class="title-ligne">
              <b-th class="newcolor">Societé</b-th>
              <b-th class="newcolor">Coefficient</b-th>
              <b-th class="newcolor">Label</b-th>
              <b-th class="newcolor">Début de configuration</b-th>
              <b-th class="newcolor">Fin de configuration</b-th>
              <b-th class="newcolor actions"></b-th>
            </b-tr>
            <template v-for="(item, index) in getAllConfigurations">
              <b-tr :key="'conf' + index + item.id">
                <b-td>{{ item.filiale_name }}</b-td>
                <b-td>{{ item.coef }}</b-td>
                <b-td>{{ item.label }}</b-td>
                <b-td>{{ item.date_debut }}</b-td>
                <b-td>{{ item.date_fin ? item.date_fin : '-' }}</b-td>
                <b-td class="actions">
                  <b-button
                    class="button-danger-style ml-2"
                    size="sm"
                    variant="info"
                    @click="duplicateConfiguration(item)"
                  >
                    <font-awesome-icon icon="copy" />
                  </b-button>
                  <b-button
                    class="button-succes-style ml-2"
                    size="sm"
                    variant="succes"
                    @click="updateConfigurationItem(item)"
                  >
                    <font-awesome-icon icon="pencil-alt" />
                  </b-button>
                </b-td>
              </b-tr>
            </template>
            <b-tr v-if="getAllConfigurations.length == 0">
              <b-td colspan="6"> Il n'y a aucun enregistrement à afficher</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="footer-style mt-4">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowConf"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="updateConfigurationModal"
      id="updateConfigurationModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une configuration</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateConfigurationModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateConfiguration"
            class="form-modal-custom-style"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Société à configurer"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0 "
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.filiale_id"
                      value-field="id"
                      text-field="name"
                      :options="getFilialsSupport"
                      required
                      @change="checkErrorsForUpdate"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Acheteur principale"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0 "
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.acheteur_id"
                      value-field="id"
                      text-field="name"
                      :options="getProFilials"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="TVA"
                    label-for="TVA"
                    class="input-modal-champ mb-0 col-3"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.tva"
                      required
                      class="b-form-select-raduis"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Début de configuration"
                    label-for="Début de configuration"
                    class="input-modal-champ mb-0 pl-0 "
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="configurationToUpdate.date_debut"
                      :clear-button="true"
                      :disabled-date="disabledStartDateUpdate"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group
                    label="Fin du configuration"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="configurationToUpdate.date_fin"
                      :clear-button="true"
                      :disabled-date="disabledEndDateUpdate"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Type de Coefficient"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.coef_type"
                      :options="coef_type_reduction_options"
                      required
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Réduction"
                    label-for="Coefficient"
                    class="input-modal-champ mb-0 "
                  >
                    <b-form-input
                      autocomplete="off"
                      v-model="configurationToUpdate.reduction"
                      required
                      type="number"
                      step="any"
                      class="b-form-select-raduis"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Type de réduction"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.reduction_type"
                      required
                      :options="coef_type_reduction_options"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Coefficient"
                    label-for="Coefficient"
                    class="input-modal-champ mb-0 col-4"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.coef"
                      required
                      type="number"
                      step="any"
                      class="b-form-select-raduis"
                      :disabled="computedDidabledCoeffToUpdate"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Label"
                    label-for="label"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="configurationToUpdate.label"
                      required
                      class="b-form-select-raduis"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label=""
                    label-for=""
                    class="input-modal-champ mb-0"
                  >
                    <button
                      type="button"
                      @click="addNewcoeffition('update')"
                      class="ml-2  btn-add-tva-unite"
                    >
                      <font-awesome-icon
                        icon="plus"
                        class="icon-plus-tva-unite"
                      />
                    </button>
                  </b-form-group>
                </div>
                <div
                  v-for="(coeff, index) in configurationToUpdate.coefs"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Coefficient"
                      label-for="Coefficient"
                      class="input-modal-champ mb-0 col-4"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.coef"
                        required
                        type="number"
                        step="any"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Label"
                      label-for="label"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="filiale-name"
                        v-model="coeff.label"
                        required
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0">
                      <b-button
                        class="button-danger-style ml-1 float-left mt-4"
                        size="sm"
                        variant="danger"
                        @click="deleteCoefficient(index, 'update')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                </div>
                <hr />
                <div v-if="configurationToUpdate.coef_type === 'pourcentage'">
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Formules:</b></p>
                  </div>
                  <div class="ModelCol mr-3">
                    <b-button
                      variant="success"
                      class="config-btn d-flex justify-content-start ml-2"
                      @click="addNewFormule"
                      ><font-awesome-icon icon="plus" /> Ajouter une
                      formule</b-button
                    >
                    <div>
                      <b-form-group
                        v-if="configurationToUpdate.formules.length > 1"
                        label=""
                        label-for=""
                        class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="and_or"
                          v-model="configurationToUpdate.formule_types"
                          required
                          :options="formule_types_options"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group
                        label="Colonne"
                        label-for="colonne"
                        class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                      >
                        <b-form-select
                          autocomplete="off"
                          :id="'condition_type'"
                          @input="CopyFields($event)"
                          :options="getNumeriqCulomn"
                          class="b-form-select-raduis"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div>
                      <div class="CopieMsg" v-if="isTextCopied">
                        Text copier
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(formu, index) in configurationToUpdate.formules"
                    :key="index"
                  >
                    <div class="ModelCol mr-3">
                      <b-form-group
                        label="Formule"
                        label-for="formule"
                        class="input-modal-champ mb-0 col-lg-11"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="filiale-name"
                          v-model="formu.formule"
                          rows="3"
                          required
                          class="b-form-select-raduis"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group class="input-modal-champ mb-0 col-lg-1">
                        <b-button
                          class="button-danger-style  mt-4"
                          size="sm"
                          variant="danger"
                          @click="deleteFormule(index, 'update')"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                      </b-form-group>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <p class="d-flex justify-content-start text-dark">
                  <b>Condition de configuration</b>
                </p>
                <div class="ModelCol mr-3">
                  <b-button
                    variant="success"
                    class="config-btn d-flex justify-content-start ml-2"
                    @click="addNewCondition"
                    ><font-awesome-icon icon="plus" /> Ajouter une
                    condition</b-button
                  >
                  <div>
                    <b-form-group
                      v-if="
                        configurationToUpdate &&
                          configurationToUpdate.conditions &&
                          configurationToUpdate.conditions.length > 1
                      "
                      label=""
                      label-for="filiale-name"
                      class="input-modal-champ mb-0 ml-2 d-flex justify-content-start"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="and_or"
                        v-model="configurationToUpdate.condition_types"
                        required
                        :options="and_or_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div
                  v-for="(condition, index) in configurationToUpdate.conditions"
                  :key="index"
                >
                  <div class="ModelCol mr-3">
                    <p class="m-0" hidden>
                      {{ (condition.index = index) }}
                    </p>
                    <b-form-group
                      label="Colonne"
                      label-for="colonne"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="condition.column"
                        required
                        :options="getColumns"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Operation"
                      label-for="Operation"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-name"
                        v-model="condition.operation"
                        required
                        :options="operation_options"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Valeur"
                      label-for="Valeur"
                      class="input-modal-champ mb-0"
                    >
                      <b-form-input
                        autocomplete="off"
                        v-model="condition.value"
                        class="b-form-select-raduis"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="input-modal-champ mb-0 ">
                      <b-button
                        class="button-danger-style  mt-4"
                        size="sm"
                        variant="danger"
                        @click="deleteCondition(index, 'update')"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-form-group>
                  </div>
                  <hr />
                </div>
                <div
                  v-if="
                    configurationToUpdate.filiale_id != null &&
                      configurationToUpdate.date_debut != null
                  "
                >
                  <div class="d-flex justify-content-start text-dark mt-1">
                    <p><b>Specifics:</b></p>
                  </div>
                  <b-form-checkbox
                    v-model="checkAllUpdate"
                    switch
                    @change="changeAllCheckColumnsUpdate"
                    >Tous</b-form-checkbox
                  >
                  <br />
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <div class="custom-class-fields-av">
                      <b-form-checkbox
                        v-for="(option, index) in computedFormatFilialList"
                        :key="index"
                        v-model="configurationToUpdate.specifics"
                        :aria-describedby="ariaDescribedby"
                        :value="{ filiale_id: option.filiale_id }"
                        @change="checkErrorsForUpdate"
                      >
                        {{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="actionModel">
              <div class="messageError">
                <div v-if="getConfigurationError" class="error">
                  <ul
                    v-if="Array.isArray(getConfigurationError)"
                    class="mb-0"
                    style="list-style-type: none;"
                  >
                    <li
                      v-for="(error, index) in getConfigurationError"
                      :key="index"
                    >
                      {{ error }}
                    </li>
                  </ul>
                  <div v-else>{{ getConfigurationError }}</div>
                </div>
                <div v-if="getErrorForSpecific" class="error">
                  <div class="d-flex flex-row justify-content-center">
                    <p>{{ getErrorForSpecific.error }}</p>
                    <div class="ml-2 bckground-icon-info ">
                      <font-awesome-icon
                        class="info-icon"
                        :id="'tooltip-societe'"
                        icon="info"
                      />
                    </div>
                  </div>
                  <b-tooltip :target="'tooltip-societe'" triggers="hover">
                    <ul class="mb-0" style="list-style-type: none;">
                      <li
                        v-for="(error, index) in getErrorForSpecific.acheteurs"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </b-tooltip>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style"
                :disabled="!validateToUpdateconf"
              >
                <span>
                  Valider
                  <div v-if="getIsLoding" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import domain from '@/environment';
export default {
  data() {
    return {
      baseURL: domain,
      isTextCopied: false,
      formule_types_options: [
        { text: 'Min', value: 'min' },
        { text: 'Max', value: 'max' }
      ],
      coef_type_reduction_options: [
        { text: 'Pourcentage', value: 'pourcentage' },
        { text: 'Montant fix', value: 'montant fix' }
      ],
      and_or_options: [
        { text: 'OU', value: 'or' },
        { text: 'ET', value: 'and' }
      ],
      operation_options: [
        {
          text: 'Egale à',
          value: '=='
        },
        {
          text: 'Pas égale à',
          value: '!='
        },
        {
          text: 'Supérieur à',
          value: '>'
        },
        {
          text: 'Inférieur à',
          value: '<'
        },
        {
          text: 'Superieur ou égale à',
          value: '>='
        },
        {
          text: 'Inferieur ou égale à',
          value: '<='
        }
      ],
      validateToUpdateconf: false,
      validateToAddconf: false,
      checkAll: false,
      checkAllUpdate: false,
      configurationToAdd: {
        filiale_id: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: null,
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [],
        conditions: [],
        specifics: [],
        formules: [{ formule: null }]
      },
      dateEndForSearch: null,
      dateStartforSearch: null,
      nom_societe: null,
      configuration: null,
      configurationToUpdate: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAllFilialSupport',
      'getAllProFilial',
      'getAllNumColums',
      'getAllColumns',
      'addNewConfiguration',
      'checkPeriod',
      'fetchAllconfigurations',
      'updateConfiguration',
      'initErrors'
    ]),
    async searchFilter(e, colonne) {
      if (e && colonne) {
        this[colonne] = e;
      }

      await this.fetchAllconfigurations({
        date_debut: this.dateStartforSearch,
        date_fin: this.dateEndForSearch,
        nom_societe: this.nom_societe,
        per_page: this.perPage,
        page: this.page
      });
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    },
    changeAllCheckColumns() {
      this.configurationToAdd.specifics = this.checkAll
        ? this.getProFilials.map(item => ({
            filiale_id: item.id
          }))
        : [];
    },
    changeAllCheckColumnsUpdate() {
      this.configurationToUpdate.specifics = this.checkAllUpdate
        ? this.getProFilials.map(item => ({ filiale_id: item.id }))
        : [];
    },
    addNewCondition(type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      } else {
        this.configurationToUpdate.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      }
    },
    addNewFormule(type) {
      if (type === 'add') {
        this.configurationToAdd.formules.push({
          formule: null
        });
      } else {
        this.configurationToUpdate.formules.push({
          formule: null
        });
      }
    },
    addNewcoeffition(type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.push({
          coef: 0,
          label: null
        });
      } else {
        this.configurationToUpdate.coefs.push({
          coef: 0,
          label: null
        });
      }
    },
    deleteCondition(index, type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.splice(index, 1);
      } else {
        this.configurationToUpdate.conditions.splice(index, 1);
      }
    },
    deleteFormule(index, type) {
      if (type === 'add') {
        this.configurationToAdd.formules.splice(index, 1);
      } else {
        this.configurationToUpdate.formules.splice(index, 1);
      }
    },
    deleteCoefficient(index, type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.splice(index, 1);
      } else {
        this.configurationToUpdate.coefs.splice(index, 1);
      }
    },
    resetModal() {
      this.configurationToAdd = {
        filiale_id: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: null,
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [],
        conditions: [],
        specifics: [],
        formules: []
      };
      this.initErrors();
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.fetchAllconfigurations({
        date_debut: this.dateStartforSearch,
        date_fin: this.dateEndForSearch,
        nom_societe: this.nom_societe,
        per_page: this.perPage,
        page: this.page
      });
    },
    async changePerPage() {
      this.page = 1;
      await this.fetchAllconfigurations({
        date_debut: this.dateStartforSearch,
        date_fin: this.dateEndForSearch,
        nom_societe: this.nom_societe,
        per_page: this.perPage,
        page: this.page
      });
    },

    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async updateConfigurationItem(data) {
      this.configuration = data;
      this.configurationToUpdate = { ...data };
      this.configurationToUpdate.specifics = this.configurationToUpdate?.specifics?.map(
        item => ({
          filiale_id: item.filiale_id
        })
      );
      this.$refs['updateConfigurationModal'].show();
      this.configurationToUpdate.date_debut = moment(
        this.configurationToUpdate.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      this.configurationToUpdate.date_fin = moment(
        this.configurationToUpdate.date_fin,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut: this.configurationToUpdate.date_debut,
        date_fin: this.configurationToUpdate.date_fin,
        specific_ids: []
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response.success) {
          this.validateToUpdateconf = true;
        } else {
          this.validateToUpdateconf = false;
        }
      }
    },
    async handleUpdateConfiguration() {
      const response = await this.updateConfiguration({
        configuration: this.configuration,
        configurationToUpdate: this.configurationToUpdate
      });
      if (response.success) {
        this.hideModal('updateConfigurationModal');
      }
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        date >= new Date(this.configurationToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        date < new Date(this.configurationToAdd.date_debut)
      );
    },

    disabledStartDateFilter(date) {
      return this.dateEndForSearch && date >= new Date(this.dateEndForSearch);
    },
    disabledEndDateFiltre(date) {
      return (
        this.dateStartforSearch && date < new Date(this.dateStartforSearch)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.configurationToUpdate.date_fin &&
        date >= new Date(this.configurationToUpdate.date_fin)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.configurationToUpdate.date_debut &&
        date < new Date(this.configurationToUpdate.date_debut)
      );
    },
    async duplicateConfiguration(data) {
      this.configurationToAdd = { ...data };
      this.configurationToAdd.specifics = this.configurationToAdd.specifics.map(
        item => ({
          filiale_id: item.filiale_id
        })
      );
      this.$refs['addConfigurationModal'].show();
      this.configurationToAdd.date_debut = moment(
        this.configurationToAdd.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      this.configurationToAdd.date_fin = moment(
        this.configurationToAdd.date_fin,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id,
        date_debut: this.configurationToAdd.date_debut,
        date_fin: this.configurationToAdd.date_fin,
        specific_ids: []
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response.success) {
          this.validateToAddconf = true;
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async handleSubmitConfiguration() {
      const response = await this.addNewConfiguration(this.configurationToAdd);
      if (response.success) {
        this.hideModal('addConfigurationModal');
      }
    },
    async checkErrorsForAdd() {
      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id,
        date_debut: this.configurationToAdd.date_debut,
        date_fin: this.configurationToAdd.date_fin,
        specific_ids: []
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response.success) {
          this.validateToAddconf = true;
          this.initErrors();
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async checkErrorsForUpdate() {
      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut: this.configurationToUpdate.date_debut,
        date_fin: this.configurationToUpdate.date_fin,
        specific_ids: []
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response.success) {
          this.validateToUpdateconf = true;
          this.initErrors();
        } else {
          this.validateToUpdateconf = false;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getFilialsSupport',
      'getProFilials',
      'getNumeriqCulomn',
      'getColumns',
      'getIsLoding',
      'getConfigurationError',
      'getAllConfigurations',
      'getTotalRowConf',
      'getErrorForSpecific'
    ]),
    computedFormatFilialList() {
      return this.getProFilials.map(item => ({ ...item, filiale_id: item.id }));
    },
    computedDisabledCoeffition() {
      if (this.configurationToAdd.coefs.length > 0) {
        return true;
      }
      return false;
    },
    computedDidabledCoeffToUpdate() {
      if (this.configurationToUpdate?.coefs?.length > 0) {
        return true;
      }
      return false;
    },
    // computedFitredSociteConfiguer() {
    //   return function(data) {
    //     let tale = this.configurationToAdd.specifics.filter(
    //       item => item.filiale_id != null
    //     );
    //     if (data != null && tale.length) {
    //       tale = tale.filter(item => item.filiale_id != data);
    //     }
    //     let nameOfSpecificsFilials = [];
    //     if (tale.length) {
    //       nameOfSpecificsFilials = tale.map(({ filiale_id }) => filiale_id);
    //     }
    //     if (nameOfSpecificsFilials.length > 0) {
    //       let t = this.getProFilials.filter(
    //         item => !nameOfSpecificsFilials.includes(item.id)
    //       );
    //       return t;
    //     }
    //     return this.getProFilials;
    //   };
    // },
    getComputedConfugiration() {
      return this.configurationToAdd.coefs;
    },
    getComputedConfugirationUpdate() {
      return this.configurationToUpdate?.coefs;
    }
  },
  watch: {
    getComputedConfugiration: {
      handler: function() {
        if (this.configurationToAdd.coefs.length > 0) {
          let sum = 0;
          this.configurationToAdd.coefs.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToAdd.coef = sum;
        }
        // if (this.configurationToAdd.coefs.length === 0) {
        //   this.configurationToAdd.coef = 0;
        // }
      },
      deep: true
    },
    getComputedConfugirationUpdate: {
      handler: function() {
        let copyCoef = this.configurationToUpdate.coef;
        if (this.configurationToUpdate?.coefs?.length > 0) {
          let sum = 0;
          this.configurationToUpdate?.coefs?.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToUpdate.coef = sum;
        }
        if (this.configurationToUpdate?.coefs?.length === 0) {
          this.configurationToUpdate.coef = copyCoef;
        }
      },
      deep: true
    }
  },
  components: {
    DatePicker,
    Card: () => import('../component/card.vue')
  },

  async mounted() {
    await this.getAllFilialSupport();
    await this.getAllProFilial();
    await this.getAllNumColums();
    await this.getAllColumns();
    await this.fetchAllconfigurations({
      date_debut: this.dateforSearch,
      date_fin: this.dateEndForSearch,
      nom_societe: this.nom_societe,
      per_page: this.perPage,
      page: this.page
    });
  }
};
</script>

<style scoped lang="scss">
.setting-filiale-sci {
  width: 100%;
  .button-danger-style,
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
  }
  .border-raduis {
    border-radius: 50%;
  }
  .input-focus-responsable-depot {
    width: 95%;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
    overflow: visible;
    text-overflow: ellipsis;
    outline: unset;
    padding: 4px;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 400;

    &:focus {
      background-color: #eeeef7;
    }
  }
  .input-focus-responsable-depot::placeholder {
    color: #c3c3d8;
    font-size: 9px;
  }
  .table-fiche {
    width: 100%;
    height: calc(100vh - 282px);
  }
  .newcolor {
    min-width: 100px;
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
  .three-points-concats {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  .back-ground-paire {
    background-color: #8d8cb7 !important;
  }
  .back-ground-impaire {
    background-color: #a0c5e8 !important;
  }
}
.bckground-icon-info {
  height: 22px;
  width: 22px;
  background-color: red;
  border-radius: 20px;
}
.info-icon {
  margin-top: 5px;
  color: #fff;
}
.btn-add-tva-unite {
  height: 25px;
  width: 25px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
  float: left;
  margin-top: 30px;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}
.icon-plus-tva-unite {
  color: #fff;
  height: 17px;
  width: 17px;
  margin-top: 3px;
}
.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.CopieMsg {
  margin-left: 50px;
  color: #4d4bac;
  font-weight: 12px;
}
.custom-class-fields-av {
  .custom-control {
    width: 30%;
  }
}
.custom-class-fields-av-update {
  .custom-control {
    width: 33%;
  }
}
.loadingFetch {
  float: left;
  width: 25px;
  height: 25px;
  font-size: 10px;
}
.widthTitle {
  width: 170px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 250px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    background-color: #fdfbfb;
    padding: 5px;
    color: #a2a2a2;
    border: none;
    border-radius: 19px;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
// .date-picker-for-filter {
//   height: 30px;
//   border-radius: 0 20px 20px 0;
//   border: 1px solid #8d8cb7;
// }
.xmark-icon {
  color: #000;
  margin-top: 35px;
}
</style>
<style lang="scss">
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.custom-select-vue-component-style {
  .multiselect__tags {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }
}
.labes-depot-name {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-left: 3%;
  margin-top: 38px;
  margin-bottom: 1px;
  width: 30%;
}
.custom-checkbox {
  align-self: center;
  margin-right: 10px;
}
.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}
.cutsom-modal-bootstrap-filiale-sci .modal-dialog {
  top: 0%;
  max-width: 70%;
}
.width-filter {
  width: 110px;
}
.date-picker-for-filter {
  border-radius: 0px 20px 20px 0px !important;
  height: 31px !important;
  border: 1px solid #8d8cb7 !important;
  outline: 0 !important;
  width: 220px !important;
  padding-left: 4px !important;
  color: #000 !important;
  background-color: #fff !important;
}
// .label-box-style {
//   background-color: #8d8cb7;
//   padding: 4px 3px;
//   border-radius: 22px 0px 0px 22px;
//   text-align: end;
//   height: 29px;
// }
</style>
